import React from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
// import { styled } from '@material-ui/core/styles';

// const StyledSelect = styled(Select)({
//   '& .Mui-disabled': {
//     WebkitTextFillColor:'rgba(0, 0, 0, 0.6)',
//   },
//   '& .MuiOutlinedInput-input.Mui-disabled': {
//     WebkitTextFillColor:'rgba(0, 0, 0, 0.87)',
//   },
// });

function Main(props) {

  const handleChange = (event) => {
    props.setZone(event.target.value);
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">Zona</InputLabel>
      <Select
        labelId="zone-select-label"
        id="zone-select"
        value={props.zone}
        label="Zone"
        onChange={handleChange}
      >
        {['Fosos','Campa'].map((zone) => {
          return <MenuItem value={zone} key={zone}>
            {zone}
            </MenuItem>;
        })}
      </Select>
    </FormControl>
  );
}

export default Main;
