import React, { useState } from "react";
import {
  Grid,
} from "@material-ui/core";

import FactorySelector from "./FactorySelector";
import ZoneSelector from "./ZoneSelector";
import WarehouseSelector from "./WarehouseSelector";
import ProviderSelector from "./ProviderSelector";

const style = {
  layout: {
    width: "auto",
    maxWidth: 400,
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 0,
    marginBottom: 0,
  },
  grid:{
    paddingBottom: 0,
    paddingRight:0,
  },
}

function FirstStepContainer(props) {
  const [factory, setFactory] = useState("");
  const [zone, setZone] = useState("");
  const [warehouse, setWarehouse] = useState("");
  const [provider, setProvider] = useState("");

  return (
    <div style={{minHeight:350}}>
      <Grid container spacing={4} style={style.layout}>
        <Grid item xs={12} style={style.grid}>
          <Grid container spacing={3}>
            <Grid item xs={12} style={style.grid}>
              <FactorySelector 
                factory={factory} 
                setFactory={setFactory}
              />
            </Grid>
            <Grid item xs={12} style={style.grid}>
              <ZoneSelector
                zone={zone}
                setZone={setZone}
              />
            </Grid>
            <Grid item xs={12} style={style.grid}>
              <WarehouseSelector
                warehouse={warehouse} 
                setWarehouse={setWarehouse}
              />
            </Grid>
            <Grid item xs={12} style={style.grid}>
              <ProviderSelector
                provider={provider}
                setProvider={setProvider}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default FirstStepContainer;
