import React from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
// import { styled } from '@material-ui/core/styles';

// const StyledSelect = styled(Select)({
//   '& .Mui-disabled': {
//     WebkitTextFillColor:'rgba(0, 0, 0, 0.6)',
//   },
//   '& .MuiOutlinedInput-input.Mui-disabled': {
//     WebkitTextFillColor:'rgba(0, 0, 0, 0.87)',
//   },
// });

function Main(props) {

  const handleChange = (event) => {
    props.setWarehouse(event.target.value);
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">Almacén</InputLabel>
      <Select
        labelId="warehouse-select-label"
        id="warehouse-select"
        value={props.warehouse}
        label="Warehouse"
        onChange={handleChange}
      >
        {['H1','H2','W1'].map((warehouse) => {
          return <MenuItem value={warehouse} key={warehouse}>
            {warehouse}
            </MenuItem>;
        })}
      </Select>
    </FormControl>
  );
}

export default Main;
