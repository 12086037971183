import React, { useEffect, useState } from 'react'
import { Card, CardActionArea, CardMedia, CircularProgress } from '@material-ui/core';
import axios from 'axios';
import { withSnackbar } from 'notistack';

function Image(props) {
  const [loading, setLoading] = useState(true);
  const [url, setUrl] = useState('');
  
  useEffect(() => {
    async function getSignedURL(path) {
      let token = await props.auth.currentUser.getIdToken();
      axios
        .get("/images/image?filename=" + encodeURIComponent(path),{
          'headers': { "x-access-token": token }
        })
        .then(response => {
          if (response.data.success){
            // console.log('SUCCESS',response.data.url)
            setUrl(response.data.url);
          }else{
            // console.log('ERRROR NO SUCCESS')
            props.enqueueSnackbar(response.error, {
              variant: 'error',
              autoHideDuration: 3000,
            });
          }
          setLoading(false);
        })
        .catch(error => {
          console.log('ERROR',error)
          props.enqueueSnackbar(error, {
            variant: 'error',
            autoHideDuration: 3000,
          });
          setLoading(false);
        });
    }
    if (loading){
      getSignedURL(props.path);
    }
  }, [loading]);

  return (
    <div>
    {loading?(
      <CircularProgress/>
    ):(
      <Card>
        <CardActionArea>
          <CardMedia
          component="img"
          image={url}
          />
        </CardActionArea>
      </Card>
    )}
    </div>
  );
}

export default withSnackbar(Image);