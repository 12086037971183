import moment from "moment";
import MomentUtils from "@date-io/moment";
import React from "react";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
  MuiThemeProvider,
  Typography,
} from "@material-ui/core";
import { createTheme } from '@material-ui/core/styles'
import PickerToolbar from "@material-ui/pickers/_shared/PickerToolbar";
import ToolbarButton from "@material-ui/pickers/_shared/ToolbarButton";
import "moment/locale/es";
import { makeStyles } from "@material-ui/core";

moment.locale("es"); // it is required to select default locale manually

export const useStyles = makeStyles({
  toolbar: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
});

const materialTheme = createTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        // backgroundColor: "#EC1527",
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        // backgroundColor: "white",
        // color: "black",
      },
    },
    MuiPickersDay: {
      day: {
        // color: "#EC1527",
        // backgroundColor:'red'
      },
      daySelected:{
        backgroundColor:'rgb(253,199,4)',
        "&:hover":{
          backgroundColor:'rgb(86,86,86)'
        },
      },
      current: {
        // color: "#EC1527",
        // backgroundColor:'yellow'
      },
    },
    // MuiDialogActions:{
    //   color:'red',
    // },
    MuiPickersModal: {
      dialogAction: {
        // color: "#EC1527",
      },
    },
  },
});

const CustomToolbar = function(props, id) {
  const { date, isLandscape, openView, setOpenView, title } = props;

  const handleChangeViewClick = (view) => (e) => {
    setOpenView(view);
  };

  const classes = useStyles();

  return (
    <PickerToolbar
      className={classes.toolbar}
      title={title}
      isLandscape={isLandscape}
      style={{backgroundColor:'rgb(253,199,4)'}}
    >
      <Typography
        variant="h6"
        // style={{ width: "100%", textAlign: "center", }}//color: "#FFFF" }}
      >
        {id}
      </Typography>
      <ToolbarButton
        onClick={handleChangeViewClick("date")}
        variant="h5"
        disabled
        selected={openView === "date"}
        label={date.format("LL")}
      />
    </PickerToolbar>
  );
};

function ScrapDatePicker(props) {
  return (
    <MuiThemeProvider theme={materialTheme}>
      <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
        <DatePicker
          ToolbarComponent={(event) => {
            return CustomToolbar(event, props.name);
          }}
          value={props.selectedDate}
          format="DD/MM/yyyy"
          autoOk
          disableFuture
          variant="static"
          maxDate={props.maxDate}
          minDate={props.minDate}
          style={{ paddingTop: "6px" }}
          onChange={(date) => {
            props.handleDateChange(date, props.type);
          }}
        />
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  );
}

export default ScrapDatePicker;
