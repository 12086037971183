import React, { useEffect, useState } from 'react';

import { FirebaseLogin } from 'firebase-login';
import { auth } from './Firebase';
import { useSnackbar } from 'notistack';
import * as firebase from 'firebase/auth';
import axios from "axios";
import Framework from "./Views/Framework";

import KalybsLogo from './Public/kalybs_color.png';

import MainBackground from './Public/background.jpg';
import { BrowserRouter } from 'react-router-dom';

const App = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [ user, setUser ] = useState(null);

  useEffect(() => {
    console.log('CURRENT USER:',auth.currentUser)
    console.log('TODO')
    // const currentUser = auth.currentUser;
    // console.log('CURRENT USER',currentUser,user)
    // if (currentUser&&user===null){
    //   console.log('CURRENT USER',currentUser,user)
    //   currentUser.getIdToken()
    //   .then((token)=>{
    //     axios.get('/users/access?uid='+user.uid,
    //       {headers: {
    //         'Content-Type': 'application/json',
    //         'x-access-token': token
    //       }}
    //     )
    //     .then((response)=>{
    //       if (response.data.user.access){
    //         setUser(response.data.user)
    //       }
    //       else{
    //         enqueueSnackbar('User does not have access. Contact the administrator', { variant: "warning"});
    //       }
    //     })
    //     .catch((error)=>{
    //       console.log('ERROR',error)
    //       enqueueSnackbar(error.message, { variant: "error"});
    //     })
    //   })
    //   .catch((error)=>{
    //     console.log('ERROR',error)
    //     console.log(error.message,error)
    //   })
    // }
  })

  const handleSignOut = () => {
    firebase.signOut(auth)
    .then(()=>{
      window.location.pathname = '/'
      setUser(null);
    })
    .catch((error)=>{
      console.log('ERROR',error)
      enqueueSnackbar('User does not have access. Contact the administrator', { variant: "warning"});
    })
  }

  // Callback after correct Login
  const handleLogin = (user) => {
    const currentUser = auth.currentUser;
    currentUser.getIdToken()
    .then((token)=>{
      axios.get('/users/access?uid='+user.uid,
        {headers: {
          'Content-Type': 'application/json',
          'x-access-token': token
        }}
      )
      .then((response)=>{
        if (response.data.user.access){
          setUser(response.data.user)
        }
        else{
          enqueueSnackbar('User does not have access. Contact the administrator', { variant: "warning"});
        }
      })
      .catch((error)=>{
        console.log('ERROR',error)
        enqueueSnackbar(error.message, { variant: "error"});
      })
    })
    .catch((error)=>{
      console.log('ERROR',error)
    })
  }

  // Callback after correct Signup. TODO: Create firestore DB record with custom fields and permisions.
  const handleSingup = (user) => {
    const currentUser = auth.currentUser;
    currentUser.getIdToken()
    .then((token)=>{
      axios.post('/users/singup',
        {user:user},
        {headers: {
          'Content-Type': 'application/json',
          'x-access-token': token
        }}
      )
      .then((response)=>{
        enqueueSnackbar("Sign up correctly", { variant: "success"});
      })
      .catch((error)=>{
        currentUser.delete().then(() => {
          enqueueSnackbar(error.message, { variant: "error"});
        }).catch((error) => {
          enqueueSnackbar(error.message, { variant: "error"});
        });
      })
    })
    .catch((error)=>{
      enqueueSnackbar(error.message, { variant: "error"});
    })
  }

  // Preconfigured Email providers. They must be enabled in the Firebase Console Auth
  const providersConfig = [
    'Google',
    'Mail',
  ]

  return (
    <div>
      {user ? (
        <BrowserRouter>
          <Framework 
            user={user} 
            auth={auth}
            signOut={handleSignOut}
          />
        </BrowserRouter>
      )
      :
      (
        <FirebaseLogin 
          mainLogo={KalybsLogo} // Optional
          backgroundImage={MainBackground} // Optional
          providersConfig={providersConfig} // Mandatory. Should have at least one provider
          onLogin={handleLogin} // Mandatory
          onSingup={handleSingup} // Mandatory
          enqueueSnackbar={enqueueSnackbar} // Mandatory. Should have a SnackbarProvider on index.js
          auth={auth} // Mandatory
          firebase={firebase} // Mandatory
        />
      )}
    </div>
  )
}

export default App