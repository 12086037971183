import React from "react";
import { 
  ListItem,
  ListItemSecondaryAction,
  Select,
  MenuItem,
  TextField,
  InputAdornment,
  ListItemIcon,
  IconButton,
  Toolbar,
  Tooltip
} from "@material-ui/core";

import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

export default function ScrapSelector(props) {

  const handleChangeType = (event) => {
    props.setScrap(event.target.value,props.scrap.percentage,props.idx)
  }

  const handleChangePercentage = (event) => {
    let value = 0
    if (event.target.value<0){
      value = 0
    }
    else if (event.target.value>100){
      value = 100
    }
    else{
      value = parseInt(event.target.value)
      if (isNaN(value)){
        value = 0
      }
    }
    props.setScrap(props.scrap.type,value,props.idx)
  }

  const handleRemove = () => {
    props.removeScrap(props.idx)
  }

  return (
    <ListItem
      className="label"
    >
      <Select
        value={props.scrap.type}
        onChange={handleChangeType}
        inputProps={{
          name: 'device',
          id: 'device-simple',
        }}
        style={{minWidth:280}}
        variant="outlined"
      >
        {props.labels.map((elem,idx)=>{
          return (<MenuItem key={idx} value={elem}>{elem}</MenuItem>)
        })}
      </Select>
      <ListItemSecondaryAction style={{textAlign:fetch?"center":"left"}}>
        <TextField
          InputProps={{
            id: 'element-id'+props.idx,
            min: "0",
            step: "1",
            max: "100",
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
          style={{width: "100px"}}
          value={props.scrap.percentage}
          variant="outlined"
          onChange={handleChangePercentage}
          type="number"
        />
        <ListItemIcon>
          <Tooltip  title="Eliminar calidad">
            <IconButton color="primary" aria-label="upload picture" component="span" style={{marginTop:'8px', marginLeft:'8px'}} onClick={handleRemove}>
              <RemoveCircleIcon />
            </IconButton>
          </Tooltip>
      </ListItemIcon>
      </ListItemSecondaryAction>
    </ListItem>
  );
}
