import React from "react";
import ViewListIcon from "@material-ui/icons/ViewList";
import ShowChartIcon from "@material-ui/icons/ShowChart";
import { NavLink } from "react-router-dom";
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";

export default function ScrapMenuList(props) {
  return (
    <List component="div" disablePadding style={{ paddingLeft: 20 }}>
      <NavLink
        to="/scrap_analytics"
        style={{
          textDecoration: "none",
          color: "unset",
        }}
      >
        <ListItem
          button
          onClick={props.toggleDrawer()}
          onKeyDown={props.toggleDrawer()}
        >
          <ListItemIcon>
            <ShowChartIcon />
          </ListItemIcon>
          <ListItemText primary="Análisis" />
        </ListItem>
      </NavLink>
      <NavLink
        to="/scrap_history"
        style={{
          textDecoration: "none",
          color: "unset",
        }}
      >
        <ListItem
          button
          onClick={props.toggleDrawer()}
          onKeyDown={props.toggleDrawer()}
        >
          <ListItemIcon>
            <ViewListIcon />
          </ListItemIcon>
          <ListItemText primary="Histórico" />
        </ListItem>
      </NavLink>
    </List>
  );
}
