import React from "react";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileValidateType);

function SecondStepContainer(props) {

  return (
    <div style={{minHeight:350}}>
      <FilePond
        labelIdle='Drag & Drop your images or <span class="filepond--label-action">Browse</span>'
        files={props.files}
        onupdatefiles={(files)=>{
          props.setFiles(files)
        }}
        allowMultiple={true}
        onerror={()=>props.setError(true)}
        maxParallelUploads={props.maxParallelUploads} 
        maxFiles={props.maxFiles} 
        acceptedFileTypes={props.acceptedFileTypes}
      />
    </div>
  );
}

export default SecondStepContainer;
