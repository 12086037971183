export const FakeDataAnalytics = [
    {
        "name": "Chatarra 1ª",
        "Operario": 35.3,
        "Modelo": 33.5,
        "ToneladasOperario": 560260.0,
        "ToneladasModelo": 530806.4
    },
    {
        "name": "Chatarra 2ª",
        "Operario": 28.3,
        "Modelo": 30.8,
        "ToneladasOperario": 88782.0,
        "ToneladasModelo": 84191.6
    },
    {
        "name": "Bote Fragmentado",
        "Operario": 10.3,
        "Modelo": 9.8,
        "ToneladasOperario": 88782.0,
        "ToneladasModelo": 84191.6
    },
    {
        "name": "Fragmentada",
        "Operario": 8.1,
        "Modelo": 9.7,
        "ToneladasOperario": 70355.0,
        "ToneladasModelo": 83521.4
    },
    {
        "name": "Viruta Acero",
        "Operario": 8.0,
        "Modelo": 8.0,
        "ToneladasOperario": 68860.0,
        "ToneladasModelo": 68860.0
    },
    {
        "name": "Demolición Industrial",
        "Operario": 6.7,
        "Modelo": 8.1,
        "ToneladasOperario": 57563.0,
        "ToneladasModelo": 69783.0
    },
    {
        "name": "Chatarra OA",
        "Operario": 2.0,
        "Modelo": 2.6,
        "ToneladasOperario": 17520.0,
        "ToneladasModelo": 22680.7
    },
    {
        "name": "Chapajo Granel",
        "Operario": 0.0,
        "Modelo": 0.1,
        "ToneladasOperario": 0.0,
        "ToneladasModelo": 1081.2
    },
    {
        "name": "Bote Hierro",
        "Operario": 0.0,
        "Modelo": 0.3,
        "ToneladasOperario": 0.0,
        "ToneladasModelo": 2436.6
    }
]