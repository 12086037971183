import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
// import Image from 'material-ui-image'
// import Image from 'react-image-resizer';
// import { withStyles } from "@material-ui/core/styles";
// import PropTypes from "prop-types";
import Swip from "./Swip";
import { CircularProgress, Slide } from "@material-ui/core";
import { withSnackbar } from "notistack";

// const styles = theme => ({
//   paperTable: {
//     width: '80%',
//     marginTop: theme.spacing(3),
//     marginBottom: theme.spacing(3),
//     marginLeft: 'auto',
//     marginRight: 'auto',
//     overflowX: 'auto',
//     boxShadow: 'none',
//   },
//   row: {
//     '&:nth-of-type(odd)': {
//       backgroundColor: theme.palette.background.default,
//     },
//   },
//   dialog:{
//     paddingTop:'48px',
//   },
//   card: {
//     // maxWidth: 345,
//   },
//   gridList: {
//     width: 500,
//     height: 450,
//   },
// });

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

function LoadDetail(props) {
  const [loading, setLoading] = useState(true);
  const [imagesURL, setImagesURL] = useState([]);
  const [imagesURLNoScrap, setImagesURLNoScrap] = useState([]);
  const [data, setData] = useState({});

  useEffect(() => {
    if (props.viewId){
      setData(props.data.find((d)=>d.id===props.viewId))
      let newImagesURL = [];
      let newImagesURLNoScrap = [];
      console.log('trobat',props.data.find((d)=>d.id===props.viewId))
      props.data.find((d)=>d.id===props.viewId).systemClassificationDetail.images.forEach(image => {
        if (image.type==='scrap'){
          newImagesURL.push(image.path);
        }else{
          newImagesURLNoScrap.push(image.path);
        }
      });
      setImagesURL(newImagesURL);
      setImagesURLNoScrap(newImagesURLNoScrap);
      
    }
    setLoading(false);
  }, [props.viewId]);

  return (
    <div>
      <Dialog
        style={{paddingTop:'48px'}}
        open={props.open}
        scroll={'body'}
        fullScreen
        TransitionComponent={Transition}
        aria-labelledby="scroll-dialog-title"
      >
        {loading?(
          <CircularProgress style={{position: 'absolute', top: '50%', left: '50%'}}/>
        ):(
          <Swip 
            specLang={props.specLang}
            imagesURL={imagesURL} 
            imagesURLNoScrap={imagesURLNoScrap}
            data={data}
            auth={props.auth}
            handleClose={props.handleClose}/>
        )}
        
      </Dialog>
    </div>
  );
}

export default withSnackbar(LoadDetail);
