import React from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";

function Main(props) {

  const handleChange = (event) => {
    props.setFactory(event.target.value);
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">Acería</InputLabel>
      <Select
        labelId="factory-select-label"
        id="factory-select"
        value={props.factory}
        label="Factory"
        onChange={handleChange}
      >
        {['Barcelona','Bilbao'].map((factory) => {
          return <MenuItem value={factory} key={factory}>
            {factory}
            </MenuItem>;
        })}
      </Select>
    </FormControl>
  );
}

export default Main;
