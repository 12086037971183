import { createTheme } from '@material-ui/core/styles';

const kalybsTheme = createTheme({
    palette: {
      primary: {
        main: "rgb(253,199,4)",
      },
      secondary: {
        main: "#777777",
      },
      error: {
        main: "#ff0000",
      },
    },
    mixins: {
      toolbar: {
        "@media (min-width:600px)": {
          minHeight: 48,
        },
      },
      container: {},
    },
    zIndex: {
      drawer: 1005,
    },
    typography: {
      useNextVariants: true,
      h1: {
        fontFamily: [
          '"Seria Semi Condenced"',
          "Roboto",
          '"Helvetica Neue"',
          "Arial",
          "sans-serif",
        ].join(","), // ""Roboto", "Helvetica", "Arial", sans-serif"
      },
      h2: {
        fontFamily: [
          '"Seria Semi Condenced"',
          "Roboto",
          '"Helvetica Neue"',
          "Arial",
          "sans-serif",
        ].join(","), // ""Roboto", "Helvetica", "Arial", sans-serif"
      },
      h3: {
        fontFamily: [
          '"Seria Semi Condenced"',
          "Roboto",
          '"Helvetica Neue"',
          "Arial",
          "sans-serif",
        ].join(","), // ""Roboto", "Helvetica", "Arial", sans-serif"
      },
      h4: {
        fontFamily: [
          '"Seria Semi Condenced"',
          "Roboto",
          '"Helvetica Neue"',
          "Arial",
          "sans-serif",
        ].join(","), // ""Roboto", "Helvetica", "Arial", sans-serif"
      },
      h5: {
        fontFamily: [
          '"Seria Semi Condenced"',
          "Roboto",
          '"Helvetica Neue"',
          "Arial",
          "sans-serif",
        ].join(","), // ""Roboto", "Helvetica", "Arial", sans-serif"
      },
      h6: {
        fontFamily: [
          '"Seria Semi Condenced"',
          "Roboto",
          '"Helvetica Neue"',
          "Arial",
          "sans-serif",
        ].join(","), // ""Roboto", "Helvetica", "Arial", sans-serif"
      },
    },
    overrides: {
      MuiContainer: {
        maxWidthLg: {
          "@media (min-width: 1280px)": {
            maxWidth: 1560,
          },
        },
      },
      MUIDataTableToolbar: {
        icon: {
          "&:hover": {
            color: "rgb(253,199,4)",
          },
        },
      },
      MuiTypography: {
        alignCenter: {
          padding: 6,
        },
      },
      MuiTableCell: {
        root: {
          padding: 6,
        },
        alignCenter: {
          padding: 16,
        },
      },
      MuiGrid: {
        grid: {
          xs: {
            maxWidth: "100%",
          },
        },
      },
      MuiDrawer: {
        root: {
          zIndex: 1000
        },
        modal: {
          zIndex: 1000
        },
      },
      MuiIconButton: {
        root: {
          color: "rgb(86,86,86)",
          height: "40px",
          width: "40px",
        },
      },
      MUIDataTableHeadCell: {
        toolButton: {
          color: "rgba(0, 0, 0, 0.54)",
          fontSize: "0.75rem",
          fontWeight: 500,
        },
      },
    },
  });

export default kalybsTheme;