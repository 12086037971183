import { USER_SIGNIN, USER_SIGNOUT, USER_FETCH } from '../Actions/user'

const initialState = {
    isAuth: 'UNKNOWN',
    billing: 'pervasive',
}

export function user(state = initialState, action) {
    switch(action.type) {
        case USER_FETCH:
            return {
                ...state,
                isAuth: action.payload,
                billing: 'pervasive'
            }
        case USER_SIGNIN:
            return {
                ...state,
                isAuth: action.payload,
                billing: 'pervasive'
            }
        case USER_SIGNOUT:
            return {
                ...state,
                isAuth: action.payload,
                billing: 'pervasive'
            }
        default:
            return state;
    }
}