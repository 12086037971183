export const FakeDataHistory = [
    {
        "license": "7629LGG",
        "codigo": 120,
        "fecha": "2021-06-11",
        "operario_id": 1234,
        "number_images": 10,
        "objects": 0,
        "botes": 0,
        "calidad_mae": 6.516822874546051,
        "calidad_1": "Chatarra 2ª",
        "prediccion_calidad_1": "Demolición Industrial",
        "calidad_2": "Demolición Industrial",
        "prediccion_calidad_2": "Chatarra 2ª",
        "calidad_3": null,
        "prediccion_calidad_3": null,
        "porcentaje_calidad_1": 20,
        "prediccion_calidad_1_porcentaje": 86.5,
        "porcentaje_calidad_2": 80,
        "prediccion_calidad_2_porcentaje": 13.5,
        "porcentaje_calidad_3": 0,
        "prediccion_calidad_3_porcentaje": 0,
        "peso_neto": 18910,
    },
    {
        "license": "3429LPG",
        "codigo": 303,
        "fecha": "2021-06-11",
        "operario_id": 1234,
        "number_images": 8,
        "objects": 0,
        "botes": 1,
        "calidad_mae": 0,
        "calidad_1": "Chatarra 1ª",
        "prediccion_calidad_1": "Chatarra 1ª",
        "calidad_2": null,
        "prediccion_calidad_2": null,
        "calidad_3": null,
        "prediccion_calidad_3": null,
        "porcentaje_calidad_1": 100,
        "prediccion_calidad_1_porcentaje": 100,
        "porcentaje_calidad_2": 0,
        "prediccion_calidad_2_porcentaje": 0,
        "porcentaje_calidad_3": 0,
        "prediccion_calidad_3_porcentaje": 0,
        "peso_neto": 25310,
    },
    {
        "license": "2845GSD",
        "codigo": 234,
        "fecha": "2021-06-11",
        "operario_id": 2234,
        "number_images": 10,
        "objects": 0,
        "botes": 0,
        "calidad_mae": 23.7,
        "calidad_1": "Chatarra 1ª",
        "prediccion_calidad_1": "Demolición Industrial",
        "calidad_2": 'Demolición Industrial',
        "prediccion_calidad_2": 'Chatarra OA',
        "calidad_3": 'Chatarra 2ª',
        "prediccion_calidad_3": 'Chatarra 1ª',
        "porcentaje_calidad_1": 50,
        "prediccion_calidad_1_porcentaje": 67,
        "porcentaje_calidad_2": 40,
        "prediccion_calidad_2_porcentaje": 17,
        "porcentaje_calidad_3": 10,
        "prediccion_calidad_3_porcentaje": 16,
        "peso_neto": 24890,

    },
    {
        "license": "7614PGG",
        "codigo": 237,
        "fecha": "2021-06-11",
        "operario_id": 1234,
        "number_images": 7,
        "objects": 0,
        "botes": 0,
        "calidad_mae": 0,
        "calidad_1": "Chatarra 1ª",
        "prediccion_calidad_1": "Chatarra 1ª",
        "calidad_2": null,
        "prediccion_calidad_2": null,
        "calidad_3": null,
        "prediccion_calidad_3": null,
        "porcentaje_calidad_1": 100,
        "prediccion_calidad_1_porcentaje": 100,
        "porcentaje_calidad_2": 0,
        "prediccion_calidad_2_porcentaje": 0,
        "porcentaje_calidad_3": 0,
        "prediccion_calidad_3_porcentaje": 0,
        "peso_neto": 22130,
    },
    {
        "license": "3532PDF",
        "codigo": 125,
        "fecha": "2021-06-11",
        "operario_id": 1234,
        "number_images": 10,
        "objects": 1,
        "botes": 0,
        "calidad_mae": 0,
        "calidad_1": "Chatarra 1ª",
        "prediccion_calidad_1": "Chatarra 1ª",
        "calidad_2": null,
        "prediccion_calidad_2": null,
        "calidad_3": null,
        "prediccion_calidad_3": null,
        "porcentaje_calidad_1": 100,
        "prediccion_calidad_1_porcentaje": 100,
        "porcentaje_calidad_2": 0,
        "prediccion_calidad_2_porcentaje": 0,
        "porcentaje_calidad_3": 0,
        "prediccion_calidad_3_porcentaje": 0,
        "peso_neto": 25550,
    },
    {
        "license": "5832KWL",
        "codigo": 262,
        "fecha": "2021-06-11",
        "operario_id": 1234,
        "number_images": 10,
        "objects": 0,
        "botes": 0,
        "calidad_mae": 0,
        "calidad_1": "Demolición Industrial",
        "prediccion_calidad_1": "Demolición Industrial",
        "calidad_2": null,
        "prediccion_calidad_2": null,
        "calidad_3": null,
        "prediccion_calidad_3": null,
        "porcentaje_calidad_1": 100,
        "prediccion_calidad_1_porcentaje": 100,
        "porcentaje_calidad_2": 0,
        "prediccion_calidad_2_porcentaje": 0,
        "porcentaje_calidad_3": 0,
        "prediccion_calidad_3_porcentaje": 0,
        "peso_neto": 22050,
    },
    {
        "license": "9222WQW",
        "codigo": 119,
        "fecha": "2021-06-11",
        "operario_id": 1234,
        "number_images": 10,
        "objects": 0,
        "botes": 0,
        "calidad_mae": 0,
        "calidad_1": "Chatarra 1ª",
        "prediccion_calidad_1": "Chatarra 1ª",
        "calidad_2": null,
        "prediccion_calidad_2": null,
        "calidad_3": null,
        "prediccion_calidad_3": null,
        "porcentaje_calidad_1": 100,
        "prediccion_calidad_1_porcentaje": 100,
        "porcentaje_calidad_2": 0,
        "prediccion_calidad_2_porcentaje": 0,
        "porcentaje_calidad_3": 0,
        "prediccion_calidad_3_porcentaje": 0,
        "peso_neto": 24510,
    },
    {
        "license": "3532PDF",
        "codigo": 236,
        "fecha": "2021-06-11",
        "operario_id": 1234,
        "number_images": 8,
        "objects": 0,
        "botes": 0,
        "calidad_mae": 0,
        "calidad_1": "Chatarra 1ª",
        "prediccion_calidad_1": "Chatarra 1ª",
        "calidad_2": null,
        "prediccion_calidad_2": null,
        "calidad_3": null,
        "prediccion_calidad_3": null,
        "porcentaje_calidad_1": 100,
        "prediccion_calidad_1_porcentaje": 100,
        "porcentaje_calidad_2": 0,
        "prediccion_calidad_2_porcentaje": 0,
        "porcentaje_calidad_3": 0,
        "prediccion_calidad_3_porcentaje": 0,
        "peso_neto": 24890,
    },
    {
        "license": "1292MDS",
        "codigo": 186,
        "fecha": "2021-06-11",
        "operario_id": 1234,
        "number_images": 7,
        "objects": 0,
        "botes": 0,
        "calidad_mae": 0,
        "calidad_1": "Chatarra 1ª",
        "prediccion_calidad_1": "Chatarra 1ª",
        "calidad_2": null,
        "prediccion_calidad_2": null,
        "calidad_3": null,
        "prediccion_calidad_3": null,
        "porcentaje_calidad_1": 100,
        "prediccion_calidad_1_porcentaje": 100,
        "porcentaje_calidad_2": 0,
        "prediccion_calidad_2_porcentaje": 0,
        "porcentaje_calidad_3": 0,
        "prediccion_calidad_3_porcentaje": 0,
        "peso_neto": 19220,
    },
    {
        "license": "0023LDF",
        "codigo": 235,
        "fecha": "2021-06-11",
        "operario_id": 1234,
        "number_images": 10,
        "objects": 0,
        "botes": 0,
        "calidad_mae": 0,
        "calidad_1": "Viruta Acero",
        "prediccion_calidad_1": "Viruta Acero",
        "calidad_2": null,
        "prediccion_calidad_2": null,
        "calidad_3": null,
        "prediccion_calidad_3": null,
        "porcentaje_calidad_1": 100,
        "prediccion_calidad_1_porcentaje": 100,
        "porcentaje_calidad_2": 0,
        "prediccion_calidad_2_porcentaje": 0,
        "porcentaje_calidad_3": 0,
        "prediccion_calidad_3_porcentaje": 0,
        "peso_neto": 17790,
    },
    {
        "license": "9182PWR",
        "codigo": 304,
        "fecha": "2021-06-11",
        "operario_id": 1234,
        "number_images": 10,
        "objects": 0,
        "botes": 0,
        "calidad_mae": 0,
        "calidad_1": "Chatarra 1ª",
        "prediccion_calidad_1": "Chatarra 1ª",
        "calidad_2": null,
        "prediccion_calidad_2": null,
        "calidad_3": null,
        "prediccion_calidad_3": null,
        "porcentaje_calidad_1": 100,
        "prediccion_calidad_1_porcentaje": 100,
        "porcentaje_calidad_2": 0,
        "prediccion_calidad_2_porcentaje": 0,
        "porcentaje_calidad_3": 0,
        "prediccion_calidad_3_porcentaje": 0,
        "peso_neto": 17800,
    },
    {
        "license": "3532PDF",
        "codigo": 320,
        "fecha": "2021-06-11",
        "operario_id": 4321,
        "number_images": 5,
        "objects": 0,
        "botes": 0,
        "calidad_mae": 0,
        "calidad_1": "Chatarra 1ª",
        "prediccion_calidad_1": "Chatarra 1ª",
        "calidad_2": null,
        "prediccion_calidad_2": null,
        "calidad_3": null,
        "prediccion_calidad_3": null,
        "porcentaje_calidad_1": 100,
        "prediccion_calidad_1_porcentaje": 100,
        "porcentaje_calidad_2": 0,
        "prediccion_calidad_2_porcentaje": 0,
        "porcentaje_calidad_3": 0,
        "prediccion_calidad_3_porcentaje": 0,
        "peso_neto": 17480,
    }
]