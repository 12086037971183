import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth"

const firebaseConfig = {
    apiKey: "AIzaSyA-axxx9ULRPVWbl6mp-6WLtqqLY1ItDlU",
    authDomain: "steelsolutions-307113.firebaseapp.com",
    projectId: "steelsolutions-307113",
    storageBucket: "steelsolutions-307113.appspot.com",
    messagingSenderId: "315315384398",
    appId: "1:315315384398:web:8d36e7e78f71cd507d931f",
    measurementId: "G-4ZNYVKMVP4"
  };

  // Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
