import { 
  Button, 
  Paper,
  Stepper,
  Step,
  StepLabel
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import FirstStepContainer from "./FirstStepContainer";
import SecondStepContainer from "./SecondStepContainer";
import ThirdStepContainer from "./ThirdStepContainer";
import { withSnackbar } from "notistack";
import { errorHandler } from "./ErrorHandler";
import store from '../../../Redux/store';
import axios from "axios";
// import { user } from "../../../Redux/Reducers/user";

const steps = ["Creación", "Carga de imágenes", "Resultados"];

const style = {
  root: {
    padding: 50,
  },
  stepper: {
    padding: `${8 * 3}px 0 ${8 * 5}px`,
  },
  layout: {
    width: "auto",
    // marginLeft: 8 * 2,
    // marginRight: 8 * 2,
    maxWidth: 1000,
    marginLeft: "auto",
    marginRight: "auto",
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: 8 * 3,
    marginLeft: 8,
  },
  paper: {
    marginTop: 8 * 5,
    marginBottom: 8 * 3,
    padding: 8 * 2,
  },
};

function Main(props) {
  const state = store.getState();
  // const [environmentList] = useState(state.user.company === 'Pervasive Technologies' ? [
  //   {name:"ma010", value:"Development - Pervasive"},
  //   {name:"ma011", value:"Development"},
  //   {name:"ma012", value:"Preproduction"},
  //   {name:"ma013", value:"Production"},
  // ] : [
  //   {name:"ma011", value:"Development"},
  //   {name:"ma012", value:"Preproduction"},
  //   {name:"ma013", value:"Production"}]);
  // const [environment, setEnvironment] = useState("");
  // const [language, setLanguage] = useState("");
  // const [warranty, setWarranty] = useState("");
  const [labels, setLabels] = useState([]);

  const [step, setStep] = useState(0);
  const [files, setFiles] = useState([]);
  // const [error, setError] = useState(false);

  const [predict, setPredict] = useState(false);
  const [results, setResults] = useState({});
  const [correction, setCorrection] = useState({});
  // const [saved, setSaved] = useState(false);

  useEffect(() => {
    async function fetchData() {
      let token = await props.auth.currentUser.getIdToken();
      const fileData = new FormData();
      files.forEach((file) => {
        fileData.append("files", file.file);
      });

      axios
        .post("/predict/"+process.env.NODE_ENV+"/"+state.user.billing, fileData, {
          headers: {
            "x-access-token": token,
            "Content-Type": "multipart/form-data",
          }
        })
        .then((data) => {
          console.log("[ROUTES][PREDICTOR] PREDICT", data);
          setResults(data.data)
          setPredict(false);
        } )
        .catch((error) => {
        props.enqueueSnackbar(errorHandler(error), { 
            variant: "warning",
            autoHideDuration: 5000,
          });
          setPredict(false)
        });
    }
    if (predict){
      handleNextButton()
      fetchData()
    }
  }, [predict, props, state.user.billing]);

  useEffect(() => {
    async function fetchData() {
      let token = await props.auth.currentUser.getIdToken();

      axios
        .get("/predict/labels/"+process.env.NODE_ENV+"/"+state.user.billing, {
          headers: {
            "x-access-token": token,
          }
        })
        .then((response) => {
          setLabels(response.data)
        } )
        .catch((error) => {
        props.enqueueSnackbar(errorHandler(error), { 
            variant: "error",
            autoHideDuration: 5000,
          });
          setPredict(false)
        });
    }
    if (labels.length === 0){
      fetchData()
    }
  }, [labels])

  useEffect(() => {
    if (results.types){
      let newCorrection = {
        product: 'scrap',
        factory: 'Barcelona',
        zone: 'Campa',
        proposedWarehouse: 'W1',
        actualWarehouse: 'W1',
        provider: 'Metales Reunidos',
        vehicle: 'Truck',
        licensePlate: '7629LGG',
        licenseImage: 'gs://....',
        externalId: Math.floor(1000 + Math.random() * 9000),
        inDatetime: new Date().toISOString(),
        downDatetime: new Date().toISOString(),
        outDatetime: new Date().toISOString(),
        preClassification: false,
        systemClassification: true,
        humanClassification: false,
        postClassification: false,
        humanUser: 'Albert',
        systemClassificationDetail: {...results},
        systemHumanDifference: 0,
      }
      setCorrection(newCorrection)
    }
  }, [results])

  function handlePredict() {
    setPredict(true)
  }

  function handleNextButton() {
    setStep(step + 1);
  }

  function handlePrevious() {
    setStep(step - 1);
  }

  async function saveData() {
    if (correction.humanClassification){
      const sum = correction.humanClassificationDetail.types.reduce((acc, cur) => acc + cur.percentage, 0)
      console.log('SUMA',sum)
      if (sum!==100){
        throw new Error('La suma de los porcentages debe ser 100. Suma actual: '+sum)
      }
      let MAE = 0
      correction.humanClassificationDetail.types.forEach((type) => {
        let systemPercentage = correction.systemClassificationDetail.types.find((t) => t.type === type.type)
        if (systemPercentage){
          systemPercentage = systemPercentage.percentage/100
          MAE = MAE+Math.abs(type.percentage/100-systemPercentage)*(type.percentage/100)
        }
      })
      correction.systemHumanDifference = parseFloat(MAE.toFixed(3))
    }
    let token = await props.auth.currentUser.getIdToken();

    axios
      .post("/scrap_sessions/Pervasive/"+process.env.NODE_ENV+"/"+state.user.billing, correction, {
        headers: {
          "x-access-token": token,
        }
      })
      .then((response) => {
        console.log('RESP',response)
        // setSaved(false)
      } )
      .catch((error) => {
        props.enqueueSnackbar(errorHandler(error), { 
          variant: "error",
          autoHideDuration: 5000,
        });
        // setSaved(false)
      });
  }

  function handleNewPrediction() {
    saveData()
    .then(()=>{
      setStep(0)
      setFiles([])
      setResults({})
      setCorrection({})
      setPredict(false)
    })
    .catch((error)=>{
      props.enqueueSnackbar(errorHandler(error), { 
        variant: "error",
        autoHideDuration: 5000,
      });
    });
  }

  const handleSaveClose = () => {
    saveData()
    .then(()=>{
      props.handleClose(true)
    })
    .catch((error)=>{
      props.enqueueSnackbar(errorHandler(error), { 
        variant: "error",
        autoHideDuration: 5000,
      });
    })
  }
  // function handleResults() {
  //   props.history.push('/multiple?environment='+environment+"&country="+language)
  // }

  // const [grf, setGrf] = useState({});
  // const [redflags, setRedflags] = useState({});

  // function getGeneralRedflagData() {
  //   axios
  //     .get("/grf/"+language+"/"+state.user.billing+"/"+environment)
  //     .then((response) => {
  //       setGrf(response.data.data)
  //     })
  //     .catch((error) => {
  //       props.enqueueSnackbar(errorHandler(error), { 
  //         variant: "warning",
  //         autoHideDuration: 5000,
  //       });
  //       setGrf({})
  //       console.log("Error getting REDFLAG:", error.message);
  //     });
  // };

  // function getRedflagsData() {
  //   axios.get("/redflags/"+language+"/"+state.user.billing+"/"+environment).then((response) => {
  //     setRedflags(response.data.data)
  //   })
  //   .catch((error) => {
  //     props.enqueueSnackbar(errorHandler(error), { 
  //       variant: "warning",
  //       autoHideDuration: 5000,
  //     });
  //     setRedflags({})
  //     console.log("Error getting REDFLAG:", error.message);
  //   });
  // }

  // useEffect(() => {
  //   if (language!==""){
  //     getGeneralRedflagData();
  //     getRedflagsData();
  //   }
  // }, [language]);

  // useEffect(() => {
  //   if (files.length===1){
  //     setFile(files[0].file);
  //     if(
  //       files[0].file.type === "application/zip" ||
  //       files[0].file.type === "application/x-zip-compressed"
  //     ){
  //       setMode('zip')
  //     }
  //     else{
  //       setMode('file')
  //     }
  //   }
  // }, [files]);

  function renderStepContainer(step) {
    switch (step) {
      case 0:
        return (
          <>
            <FirstStepContainer
              // environmentList={environmentList}
              // setLanguage={setLanguage}
              // setWarranty={setWarranty}
              // environment={environment}
              // setEnvironment={setEnvironment}
            ></FirstStepContainer>
            <div style={style.buttons}>
              <Button
                key='next'
                // disabled={language === ""}
                onClick={() => handleNextButton()}
                style={style.button}
                variant="contained"
              >
                Siguiente
              </Button> 
            </div>
          </>
        );
      case 1:
        return (
          <>
            <SecondStepContainer
              files={files}
              setFiles={setFiles}
              // setError={setError}
              maxParallelUploads={4} 
              maxFiles={30} 
              acceptedFileTypes={[
                "image/png",
                "image/jpeg",
              ]} 
            ></SecondStepContainer>
            <div style={style.buttons}>
              <Button
                key='prev'
                style={style.button}
                onClick={() => handlePrevious()}
                variant="outlined"
              >
                Anterior
              </Button>
              <Button
                key='predict'
                disabled={files.length === 0}
                onClick={() => handlePredict()}
                style={style.button}
                variant="contained"
              >
                Clasificar
              </Button>
            </div>
          </>
        );
      case 2:
        return (
          <>
            <ThirdStepContainer
              predict={predict}
              correction={correction}
              setCorrection={setCorrection}
              labels={labels}
            ></ThirdStepContainer>
            <div style={style.buttons}>
              <Button
                key='new'
                disabled={predict}
                style={style.button}
                onClick={() => handleNewPrediction()}
                variant="outlined"
              >
                Guardar & Nueva
              </Button>
              <Button
                key='save'
                disabled={predict}
                onClick={() => handleSaveClose()}
                style={style.button}
                variant="contained"
              >
                Guardar & Cerrar
              </Button>
            </div>
          </>
        );
      default: 
        return (null)
    }
  }

  return (
    <Paper style={style.paper}>
      <Stepper 
        activeStep={step} 
        alternativeLabel
        style={style.stepper}
        >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {renderStepContainer(step)}
    </Paper>
  );
}

export default withSnackbar(Main);
