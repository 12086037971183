export const lang = {
  en: {
    add_scrap_session: 'Add Scrap Session',
    tones: 'Metric tones',
    loads: 'Loads processed',
    from: 'From',
    until: 'Until',
    graph_title: 'Scrap processed distribution',
    graph_subtitle: 'Worker vs System',
    legend_name_worker: 'Worker',
    legend_name_system: 'System',
    history_details: 'Details',
    history_code: 'Code',
    history_date: 'Date',
    history_license: 'License Plate',
    history_worker_id: 'Worker ID',
    history_number_image: 'Number of images',
    history_objects: 'Large Objects',
    history_gas: 'Gas Cylinders',
    history_diffs: 'Difference',
    history_1_type: '1ˢᵗ Type',
    history_1p_type: '1ˢᵗ Predicted Type',
    history_2_type: '2ⁿᵈ Type',
    history_2p_type: '2ⁿᵈ Predicted Type',
    history_3_type: '3ʳᵈ Type',
    history_3p_type: '3ʳᵈ Predicted Type',
    history_p1_type: 'Percentage 1ˢᵗ Type',
    history_p1p_type: 'Percentage 1ˢᵗ Predicted Type',
    history_p2_type: 'Percentage 2ⁿᵈ Type',
    history_p2p_type: 'Percentage 2ⁿᵈ Predicted Type',
    history_p3_type: 'Percentage 3ʳᵈ Type',
    history_p3p_type: 'Percentage 3ʳᵈ Predicted Type',
    details_scrap: 'Scrap',
    details_no_scrap: 'No Scrap',
    details_details: 'Details',
    details_load: 'Load ID',
    details_date: 'Date',
    details_time: 'Time',
    details_weight: 'Weight',
    details_worker: 'Worker',
    details_license: 'License Plate',
    details_provider: 'Provider',
    details_type: 'Type',
    details_percentage: 'Percentage',
    details_human: 'Human',
    details_system: 'System',
    details_water: 'Water',
    details_land: 'Non Ferric',
    details_other: 'Other'
  },
  es: {
    add_scrap_session: 'Añadir descarga',
    tones: 'Toneladas',
    loads: 'Descargas procesadas',
    from: 'Desde',
    until: 'Hasta',
    graph_title: 'Distribución de chatarra procesada',
    graph_subtitle: 'Operario vs Sistema',
    legend_name_worker: 'Operario',
    legend_name_system: 'Sistema',
    history_details: 'Detalles',
    history_license: 'Matrícula',
    history_code: 'Código',
    history_date: 'Fecha',
    history_worker_id: 'ID Operario',
    history_number_image: 'Número de imágenes',
    history_objects: 'Objetos Grandes',
    history_gas: 'Botes Cerradoes',
    history_diffs: 'Diferencia',
    history_1_type: '1ʳ Tipo',
    history_1p_type: '1ʳ Tipo Predecido',
    history_2_type: '2ᵒ Tipo',
    history_2p_type: '2ᵒ Tipo Predecido',
    history_3_type: '3ʳ Tipo',
    history_3p_type: '3ʳ Tipo Predecido',
    history_p1_type: 'Porcentaje 1ʳ Tipo',
    history_p1p_type: 'Porcentaje 1ʳ Tipo Predecido',
    history_p2_type: 'Porcentaje 2ᵒ Tipo',
    history_p2p_type: 'Porcentaje 2ᵒ Tipo Predecido',
    history_p3_type: 'Porcentaje 3ʳ Tipo',
    history_p3p_type: 'Porcentaje 3ʳ Tipo Predecido',
    details_scrap: 'Chatarra',
    details_no_scrap: 'No Chatarra',
    details_details: 'Detalles',
    details_load: 'ID Carga',
    details_date: 'Fecha',
    details_time: 'Hora',
    details_weight: 'Peso',
    details_worker: 'Operario',
    details_license: 'Matrícula',
    details_provider: 'Proveedor',
    details_type: 'Tipo',
    details_percentage: 'Porcentaje',
    details_human: 'Operario',
    details_system: 'Sistema',
    details_water: 'Agua',
    details_land: 'No Férricos',
    details_other: 'Otros'
  }
}
