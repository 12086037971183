import React, { useEffect, useState } from "react";
import { 
  LinearProgress,
  List,
  Typography
} from "@material-ui/core";
import { withTheme } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';

import ScrapSelector from "./ScrapSelector";
import ScrapAddElement from "./ScrapAddElement";

function ThirdStepContainer(props) {
  const [edited, setEdited] = useState(false);


  const handleChange = (type,percentage,idx) => {
    let newCorrection = {...props.correction}
    if (!edited) { //
      setEdited(true)
      let copy = Object.assign({}, props.correction.systemClassificationDetail);//{...props.correction.systemClassificationDetail};
      copy.types = [...props.correction.systemClassificationDetail.types]

      newCorrection.humanClassification = true;
      newCorrection.humanClassificationDetail = copy;
    }
    newCorrection.humanClassificationDetail.types[idx] = {
      type: type,
      percentage: percentage
    }
    console.log('NEW CORR',newCorrection);
    props.setCorrection(newCorrection);
  };

  const handleRemove = (idx) => {
    let newCorrection = {...props.correction}
    if (!edited) { //
      setEdited(true)
      let copy = Object.assign({}, props.correction.systemClassificationDetail);//{...props.correction.systemClassificationDetail};
      copy.types = [...props.correction.systemClassificationDetail.types]

      newCorrection.humanClassification = true;
      newCorrection.humanClassificationDetail = copy;
    }
    newCorrection.humanClassificationDetail.types.splice(idx, 1);
    props.setCorrection(newCorrection);
  }

  const handleAdd = () => {
    let newCorrection = {...props.correction}
    if (!edited) { //
      setEdited(true)
      let copy = Object.assign({}, props.correction.systemClassificationDetail);//{...props.correction.systemClassificationDetail};
      copy.types = [...props.correction.systemClassificationDetail.types]

      newCorrection.humanClassification = true;
      newCorrection.humanClassificationDetail = copy;
    }
    newCorrection.humanClassificationDetail.types.push({
      type: '',
      percentage: 0
    });
    props.setCorrection(newCorrection);
  }

  // console.log('SCRAP',scrap);

  // useEffect(() => {
  //   if (scrap.systemClassification) {
  //     let newCorrection = {...props.correction};
  //   //   newCorrection.humanClassificationDetail = scrap;
  //   //   newCorrection.modifiedByHuman = true;
  //   //   newCorrection.humanClassification = true;
  //   //   props.setCorrection(newCorrection);
  //     console.log('SET CORRECTION',newCorrection)
  //   }
  //   console.log('TENIM SCRAP!!!!!!',scrap)
  // }, [scrap]);

  return (
    <div style={{minHeight:350}}> 
      {props.predict ?(
        <LinearProgress variant="indeterminate" />
      ):(
        <div>
          <List style={{maxWidth:'60%', margin:'auto'}}>  
            {edited?(
              props.correction.humanClassificationDetail.types.map((elem,idx)=>{
                return <ScrapSelector 
                  key={idx}
                  idx={idx}
                  labels={props.labels}
                  scrap={elem}
                  setScrap={handleChange}
                  removeScrap={handleRemove}
                />
              })
            ):(
              props.correction.systemClassificationDetail.types.map((elem,idx)=>{
                return <ScrapSelector 
                  key={idx}
                  idx={idx}
                  labels={props.labels}
                  scrap={elem}
                  setScrap={handleChange}
                  removeScrap={handleRemove}
                />
              })
            )}
            {edited?(
              props.correction.humanClassificationDetail.types.length<3?(
                <ScrapAddElement
                  addScrap={handleAdd}
                />
              ):(
                null
              )
            ):(
              props.correction.systemClassificationDetail.types.length<3?(
                <ScrapAddElement
                  addScrap={handleAdd}
                />
              ):(
                null
              )
            )}
          </List>
        </div>
      )}
    </div>
  );
}

export default withTheme(withSnackbar(ThirdStepContainer));