// Error handler. To imporove error messages.
export const errorHandler = (error) => {
  console.log('ERROR to process:',error)
  let status = ""
  let message = ""
  if (error.response){
    if (error.response.status){
      status = error.response.status
    }
    if (error.response.data){
      if (error.response.data.code){
        message = error.response.data.code
      }
      if (error.response.data.message){
        if (error.response.data.message.detail){
          if (typeof(error.response.data.message.detail)===typeof("str")){
            message = message + error.response.data.message.detail + " ";
          }
          else if (error.response.data.message.detail.length!==0){
            error.response.data.message.detail.forEach((m)=>{
              if (m.loc){
                if (m.loc.length!==0){
                  m.loc.forEach((l)=>{
                    message = message + l + ' - '
                  })
                }
              }
              if (m.msg){
                message = message + m.msg + ". "
              }
            })
          }
        }
        else if (error.response.data.message.message){
          message = error.response.data.message.message;
        }
        else if (typeof(error.response.data.message)===typeof("str")){
          message = error.response.data.message;
        }
      }
    }
  }
  if (error.message){
    message = message + error.message
  }
  if (message!==""){
    message = ": " + message
  }
  return `Error ${status}${message}`
};
