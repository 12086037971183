import React from "react";
import { 
  ListItem,
  ListItemIcon,
  IconButton,
  Tooltip
} from "@material-ui/core";

import AddCircleIcon from '@material-ui/icons/AddCircle';

export default function ScrapAddElement(props) {

  const handleAdd = () => {
    props.addScrap()
  }

  return (
    <ListItem
      className="label"
      style={{display:'block', textAlign:'center'}}
    >
      <ListItemIcon >
        <Tooltip title="Añadir calidad">
          <IconButton color="primary" aria-label="upload picture" component="span" style={{marginLeft:'8px'}} onClick={handleAdd}>
            <AddCircleIcon />
          </IconButton>
        </Tooltip>
    </ListItemIcon>
    </ListItem>
  );
}
