import * as React from 'react'

import {
  Typography,
  Box,
  Dialog,
  AppBar,
  Toolbar,
  Slide,
  IconButton
} from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles'

import CloseIcon from '@material-ui/icons/Close'

import { lang } from '../Languages/lang'

import Stepper from './Stepper';

const useStyles = makeStyles((theme) => ({
  main:{
    marginTop: theme.spacing(6),
    width:'80%',
    margin:'auto',
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

export default function Main(props) {
  // const [activeStep, setActiveStep] = React.useState(0)
  // const [skipped, setSkipped] = React.useState(new Set())

  const { main } = useStyles()

  const specLang = props.lang || 'es'

  // const isStepOptional = (step) => {
  //   return step === 1
  // }

  // const isStepSkipped = (step) => {
  //   return skipped.has(step)
  // }

  // const handleNext = () => {
  //   let newSkipped = skipped
  //   if (isStepSkipped(activeStep)) {
  //     newSkipped = new Set(newSkipped.values())
  //     newSkipped.delete(activeStep)
  //   }

  //   setActiveStep((prevActiveStep) => prevActiveStep + 1)
  //   setSkipped(newSkipped)
  // }

  // const handleBack = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep - 1)
  // }

  // const handleSkip = () => {
  //   if (!isStepOptional(activeStep)) {
  //     // You probably want to guard against something like this,
  //     // it should never occur unless someone's actively trying to break something.
  //     throw new Error("You can't skip a step that isn't optional.")
  //   }

  //   setActiveStep((prevActiveStep) => prevActiveStep + 1)
  //   setSkipped((prevSkipped) => {
  //     const newSkipped = new Set(prevSkipped.values())
  //     newSkipped.add(activeStep)
  //     return newSkipped
  //   })
  // }

  // const handleReset = () => {
  //   setActiveStep(0)
  // }

  const handleClose = (reload) => {
    props.handleOpen(reload)
  }

  return (
    <Dialog
        fullScreen
        style={{zIndex:1200}}
        open={props.open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <Typography
              sx={{ ml: 2, flex: 1 }}
              style={{ width: '100%' }}
              variant='h6'
              component='div'
            >
              {lang[specLang].add_scrap_session}
            </Typography>
            <IconButton color='inherit' onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Box className={main}>
        <Stepper 
          auth={props.auth}
          handleClose={handleClose}
        />
    </Box>
      </Dialog>
  )
}
