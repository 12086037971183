export const USER_SIGNIN = "USER_SIGNIN";
export const USER_SIGNOUT = "USER_SIGNOUT";
export const USER_FETCH = "USER_FETCH"

export const userFetch = () => {
    return {
        type: USER_FETCH,
        payload: 'UNKNOWN'
    }
}
export const userSignIn = () => {
    return {
        type: USER_SIGNIN,
        payload: 'TRUE'
    }
}

export const userSignOut = () => {
    return {
        type: USER_SIGNOUT,
        payload: 'FALSE'
    }
}
