import React from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
// import { styled } from '@material-ui/core/styles';

// const StyledSelect = styled(Select)({
//   '& .Mui-disabled': {
//     WebkitTextFillColor:'rgba(0, 0, 0, 0.6)',
//   },
//   '& .MuiOutlinedInput-input.Mui-disabled': {
//     WebkitTextFillColor:'rgba(0, 0, 0, 0.87)',
//   },
// });

function Main(props) {

  const handleChange = (event) => {
    props.setProvider(event.target.value);
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">Proveedor</InputLabel>
      <Select
        labelId="provider-select-label"
        id="provider-select"
        value={props.provider}
        label="Provider"
        onChange={handleChange}
      >
        {['Metales Reunidos','Jorge Perez','Zunohka'].map((provider) => {
          return <MenuItem value={provider} key={provider}>
            {provider}
            </MenuItem>;
        })}
      </Select>
    </FormControl>
  );
}

export default Main;
