// External imports
import React from "react";
// import GridOnIcon from "@material-ui/icons/GridOn";
import ShowChartIcon from "@material-ui/icons/ShowChart";
import MemoryIcon from "@material-ui/icons/Memory";
import { NavLink } from "react-router-dom";
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
// Internal imports
import { ELECTRODES_LAST_ROUTE } from "../../Routes";

export default function ElectrodesMenuList(props) {
  return (
    <List component="div" disablePadding style={{ paddingLeft: 20 }}>
      <NavLink
        to={ELECTRODES_LAST_ROUTE}
        style={{
          textDecoration: "none",
          color: "unset",
        }}
      >
        <ListItem
          button
          onClick={props.toggleDrawer()}
          onKeyDown={props.toggleDrawer()}
        >
          <ListItemIcon>
            <MemoryIcon />
          </ListItemIcon>
          <ListItemText primary="Última captura" />
        </ListItem>
      </NavLink>
      <NavLink
        to="/electrodes_analytics"
        style={{
          textDecoration: "none",
          color: "unset",
        }}
      >
        <ListItem
          button
          onClick={props.toggleDrawer()}
          onKeyDown={props.toggleDrawer()}
        >
          <ListItemIcon>
            <ShowChartIcon />
          </ListItemIcon>
          <ListItemText primary="Análisis" />
        </ListItem>
      </NavLink>
    </List>
  );
}
