import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SwipeableViews from 'react-swipeable-views';
import {
    Grid,
    AppBar,
    Toolbar,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Divider,
    Paper,
    TableCell,
} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import PermIdentity from "@material-ui/icons/PermIdentity";
import Store from "@material-ui/icons/Store";
import DateRange from "@material-ui/icons/DateRange";
import AccessTime from "@material-ui/icons/AccessTime";
import BookIcon from "@material-ui/icons/Book";
import CloseIcon from "@material-ui/icons/Close";
import ChromeReaderModeIcon from '@material-ui/icons/ChromeReaderMode';
import moment from 'moment';
import Image from './Image';

import { lang } from '../Languages/lang';

const CustomTableCell = withStyles(theme => ({
    head: {
      backgroundColor: theme.palette.text.secondary,
      color: theme.palette.common.white,
      fontSize: '1rem',
      weight: '500',
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

const styles = {
  slide: {
    padding: 15,
    minHeight: 100,
    // height:500,//"calc(100vh-48px)",
  },
  slide1: {
    padding: 15,
    minHeight: 100,
    height:600,//"calc(100vh-48px)",
  },
  slide2: {
    padding: 15,
    minHeight: 100,
    height:300,//"calc(100vh-48px)",
  },
  row: {
    '&:nthOfType(odd)': {
      backgroundColor: 'red',//theme.palette.background.default,
    },
    backgroundColor: '#fafafa',
  },
  paperTable: {
    width: '80%',
    marginTop: 24,//theme.spacing.unit * 3,
    marginBottom: 24,//theme.spacing.unit * 3,
    marginLeft: 'auto',
    marginRight: 'auto',
    overflowX: 'auto',
    boxShadow: 'none',
  }
};

class Swip extends React.Component {
  state = {
    index: 0,
  };

  handleChange = (event, value) => {
    this.setState({
      index: value,
    });
  };

  handleChangeIndex = index => {
    this.setState({
      index,
    });
  };

  render() {
    const { index } = this.state;

    const { specLang } = this.props;

    return (
      <div>
        <AppBar position="sticky" color="default">
            <Toolbar style={{position:'absolute', minHeight:'48px'}}>
                <IconButton edge="start" color="inherit" onClick={this.props.handleClose} aria-label="close">
                    <CloseIcon />
                </IconButton>
            </Toolbar>
            <Tabs 
                value={index}  
                onChange={this.handleChange}
                indicatorColor="secondary"
                textColor="secondary"
                variant={null}
                style={{ margin: "auto" }}
            >
                <Tab style={{ margin: "auto" }} label={lang[specLang].details_scrap} />
                <Tab style={{ margin: "auto" }} label={lang[specLang].details_no_scrap} />
                <Tab style={{ margin: "auto" }} label={lang[specLang].details_details} />
            </Tabs>
        </AppBar>
        <SwipeableViews index={index} onChangeIndex={this.handleChangeIndex}>
          <div style={Object.assign({}, styles.slide)}>
              <div>
                <Grid container spacing={3}>
                    {this.props.imagesURL.map((n, idx) => {
                        return (
                        <Grid key={idx} item xs={12} sm={6} md={4}>
                            <Image 
                                path={n}
                                auth={this.props.auth}
                            />
                        </Grid>
                        )
                    })}
                </Grid> 
              </div>
          </div>
          <div style={Object.assign({}, styles.slide)}>
              <div>
                <Grid container spacing={3}>
                    {this.props.imagesURLNoScrap.map((n, idx) => {
                        return (
                        <Grid key={idx} item xs={12} sm={6} md={4}>
                            <Image 
                                path={n}
                                auth={this.props.auth}
                            />
                        </Grid>
                        )
                    })}
                </Grid> 
              </div>
            
          </div>
          <div style={Object.assign({}, styles.slide)}>
          <Grid container spacing={0}>
                <Grid item xs={6}>
                    <List component="nav">
                    <ListItem>
                        <ListItemIcon>
                        <BookIcon/>
                        </ListItemIcon>
                        <ListItemText
                        secondary={lang[specLang].details_load}
                        primary={this.props.data.externalId}
                        />
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <ListItemIcon>
                        <DateRange/>
                        </ListItemIcon>
                        <ListItemText
                        secondary={lang[specLang].details_date}
                        primary={moment(new Date(this.props.data.inDatetime), 'YYYY-MM-DD').format('DD/MM/YYYY')}
                        />
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <ListItemIcon>
                        <AccessTime/>
                        </ListItemIcon>
                        <ListItemText
                        secondary={lang[specLang].details_time}
                        primary={moment(new Date(this.props.data.inDatetime), 'HH:mm:ss').format('HH:mm:ss')}
                        />
                    </ListItem>
                    <Divider />
                    {/* <ListItem>
                        <ListItemIcon>
                        <TitleIcon/>
                        </ListItemIcon>
                        <ListItemText
                        secondary={lang[specLang].details_weight}
                        primary={this.props.data.peso_neto}
                        />
                    </ListItem> 
                    <Divider /> */}
                    </List>
                </Grid>
                <Grid item xs={6}>
                    <List>
                        <ListItem>
                            <ListItemIcon>
                            <PermIdentity/>
                            </ListItemIcon>
                            <ListItemText
                            secondary={lang[specLang].details_worker}
                            primary={this.props.data.humanUser}
                            />
                        </ListItem>
                        <Divider />
                        <ListItem>
                            <ListItemIcon>
                            <ChromeReaderModeIcon/>
                            </ListItemIcon>
                            <ListItemText
                            secondary={lang[specLang].details_license}
                            primary={this.props.data.licensePlate}
                            />
                        </ListItem>
                        <Divider />
                        <ListItem>
                            <ListItemIcon>
                            <Store/>
                            </ListItemIcon>
                            <ListItemText
                            secondary={lang[specLang].details_provider}
                            primary={this.props.data.provider}
                            />
                        </ListItem>
                        <Divider />
                    </List>
                </Grid>
                </Grid>
                <Grid container spacing={0} style={{display:'-webkit-box'}}>
                {this.props.data.humanClassification?(
                    <Grid item xs={6}>
                        <Paper style={Object.assign({}, styles.paperTable)}>
                        <Typography variant="h6" component="h3" align='center'>
                            {lang[specLang].details_human}
                        </Typography>
                        <Table>
                            <TableHead>
                            <TableRow>
                                <CustomTableCell align="center">{lang[specLang].details_type}</CustomTableCell>
                                <CustomTableCell align="center"> {lang[specLang].details_percentage}</CustomTableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {this.props.data.humanClassificationDetail.types.map((row,idx)=>{
                                return (
                                <TableRow key={row.type} style={Object.assign({}, styles.row)}>
                                    <CustomTableCell align="center">{row.type}</CustomTableCell>
                                    <CustomTableCell align="center">{row.percentage + " %"}</CustomTableCell>
                                </TableRow>
                                )
                            })}
                            </TableBody>
                        </Table>
                        </Paper>
                        <Paper style={Object.assign({}, styles.paperTable)}>
                        {/* <Table>
                            <TableBody>
                                <TableRow style={Object.assign({}, styles.row)}>
                                <CustomTableCell align="center">{lang[specLang].details_water}</CustomTableCell>
                                <CustomTableCell align="center">{this.props.data.humanClassificationDetail.water ? (this.props.data.humanClassificationDetail.water + " %") : (0+" %")}</CustomTableCell>
                                </TableRow>
                                <TableRow>
                                <CustomTableCell align="center">{lang[specLang].details_land}</CustomTableCell>
                                <CustomTableCell align="center">{this.props.data.humanClassificationDetail.nonFerric ? (this.props.data.humanClassificationDetail.nonFerric + " %") : (0+" %")}</CustomTableCell>
                                </TableRow>
                                <TableRow style={Object.assign({}, styles.row)}>
                                <CustomTableCell align="center">{lang[specLang].details_other}</CustomTableCell>
                                <CustomTableCell align="center">{this.props.data.humanClassificationDetail.other ? (this.props.data.humanClassificationDetail.other + " %") : (0+" %")}</CustomTableCell>
                                </TableRow>
                            </TableBody>
                        </Table> */}
                        </Paper>
                    </Grid>
                ):(
                    <Grid item xs={6} style={{position:'relative',verticalAlign:'top',paddingTop:'0px'}}>
                        <Paper style={Object.assign({}, styles.paperTable)}>
                            <Typography variant="h6" component="h3" align='center'>
                                Datos de Operario no disponibles
                            </Typography> 
                        </Paper>
                    </Grid>
                )}
                {this.props.data.systemClassification?(
                    <Grid item xs={6}>
                        <Paper style={Object.assign({}, styles.paperTable)}>
                        <Typography variant="h6" component="h3" align='center'>
                        {lang[specLang].details_system}
                        </Typography>
                        <Table>
                            <TableHead>
                            <TableRow>
                                <CustomTableCell align="center">{lang[specLang].details_type}</CustomTableCell>
                                <CustomTableCell align="center">{lang[specLang].details_percentage}</CustomTableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {this.props.data.systemClassificationDetail.types.map((row,idx)=>{
                                return (
                                <TableRow key={row.type} style={Object.assign({}, styles.row)}>
                                    <CustomTableCell align="center">{row.type}</CustomTableCell>
                                    <CustomTableCell align="center">{row.percentage + " %"}</CustomTableCell>
                                </TableRow>
                                )
                            })}
                            </TableBody>
                        </Table>
                        </Paper>
                        <Paper style={Object.assign({}, styles.paperTable)}>
                        {/* <Table>
                            <TableBody>
                                <TableRow style={Object.assign({}, styles.row)}>
                                <CustomTableCell align="center">{lang[specLang].details_water}</CustomTableCell>
                                <CustomTableCell align="center">{this.props.data.systemClassificationDetail.water ? (this.props.data.systemClassificationDetail.water + " %") : (0+" %")}</CustomTableCell>
                                </TableRow>
                                <TableRow >
                                <CustomTableCell align="center">{lang[specLang].details_land}</CustomTableCell>
                                <CustomTableCell align="center">{this.props.data.systemClassificationDetail.nonFerric ? (this.props.data.systemClassificationDetail.nonFerric + " %") : (0+" %")}</CustomTableCell>
                                </TableRow>
                                <TableRow style={Object.assign({}, styles.row)}>
                                <CustomTableCell align="center">{lang[specLang].details_other}</CustomTableCell>
                                <CustomTableCell align="center">{this.props.data.systemClassificationDetail.other ? (this.props.data.systemClassificationDetail.other + " %") : (0+" %")}</CustomTableCell>
                                </TableRow>
                            </TableBody>
                        </Table> */}
                        </Paper>
                    </Grid> 
                ):(
                    <Grid item xs={6} style={{position:'relative',verticalAlign:'top',paddingTop:'0px'}}>
                        <Paper style={Object.assign({}, styles.paperTable)}>
                            <Typography variant="h6" component="h3" align='center'>
                                Datos de Sistema no disponibles
                            </Typography> 
                        </Paper>
                    </Grid>
                )}
            </Grid>
          </div>
        </SwipeableViews>
      </div>
    );
  }
}

// Swip.propTypes = {
//     classes: PropTypes.object.isRequired
// };

export default Swip;