import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid, Divider, Button, Dialog, DialogActions } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Avatar from '@material-ui/core/Avatar'
import ListItemText from '@material-ui/core/ListItemText'
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom'
import TitleIcon from '@material-ui/icons/Title'
import ListNumberedIcon from '@material-ui/icons/FormatListNumbered'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import moment from 'moment'

import DateData from '../Utils/DateData'
import ScrapDatePicker from '../Components/ScrapDatePicker'

import { FakeDataAnalytics } from '../Data/FakeAnalytics'
import { FakeDataHistory } from '../Data/FakeHistory'
import { lang } from '../Languages/lang'

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts'

import { DefaultTooltipContent } from 'recharts/lib/component/DefaultTooltipContent'

const CustomTooltip = (props) => {
  // we don't need to check payload[0] as there's a better prop for this purpose
  if (!props.active) {
    // I think returning null works based on this: http://recharts.org/en-US/examples/CustomContentOfTooltip
    return null
  }
  // mutating props directly is against react's conventions
  // so we create a new payload with the name and value fields set to what we want
  const newPayload = [
    // {
    //   // all your data which created the tooltip is located in the .payload property
    //   //value: props.payload[0].payload.name + '%',
    //   unit: '%'
    //   // you can also add "unit" here if you need it
    // },
    ...props.payload
  ]

  if (newPayload.length > 1) {
    newPayload[0].unit = '%'
    newPayload[1].unit = '%'
  }
  // we render the default, but with our overridden payload
  return <DefaultTooltipContent {...props} payload={newPayload} />
}

const styles = (theme) => ({
  divider: {
    marginBottom: theme.spacing(2)
  }
})

class Analytics extends React.Component {
  state = {
    fromDate: new DateData(), // From date data.
    toDate: new DateData(), // To date data
    openFromCalendar: false,
    openToCalendar: false
  }

  handleOpenCalendar(event) {
    const newState = { ...this.state }

    if (event === 'from') {
      newState.openFromCalendar = true
    } else if (event === 'to') {
      newState.openToCalendar = true
    }
    this.setState(newState)
  }

  /* Updates dates selected by user */
  handleDateChange(event, which) {
    const newState = { ...this.state }

    const newDate = event.toDate()

    if (which === 'from') {
      newState.fromDate.dateSelected = newDate
    } else if (which === 'to') {
      newState.toDate.dateSelected = newDate
    }
    this.setState(newState)
  }

  /* Updates state from calendar. In case that event is "to" then fetch. */
  handleCloseCalendar(event) {
    const newState = { ...this.state }

    if (event === 'from') {
      newState.openFromCalendar = false
      newState.openToCalendar = true
      newState.fromDate.date = newState.fromDate.dateSelected
      this.setState(newState)
    } else if (event === 'to') {
      newState.openFromCalendar = false
      newState.openToCalendar = false
      newState.toDate.date = newState.toDate.dateSelected
      this.setState(newState)
    } else {
      newState.openFromCalendar = false
      newState.openToCalendar = false
      this.setState(newState)
    }
  }

  render() {
    const { classes } = this.props

    const specLang = this.props.lang || 'es'

    return (
      <div>
        <Grid container spacing={4} style={{ width: '50%', margin: 'auto' }}>
          <Grid item xs={6}>
            <Button
              variant='contained'
              color='primary'
              onClick={this.handleOpenCalendar.bind(this, 'from')}
              style={{ width: '100%' }}
              value='from'
            >
              {lang[specLang].from + ' '}
              {moment(this.state.fromDate.date, 'YYYYMMDDhhmmss').format(
                'DD/MM/YYYY'
              )}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant='contained'
              color='primary'
              onClick={this.handleOpenCalendar.bind(this, 'to')}
              style={{ width: '100%' }}
              value='to'
            >
              {lang[specLang].until + ' '}
              {moment(this.state.toDate.date, 'YYYYMMDDhhmmss').format(
                'DD/MM/YYYY'
              )}
            </Button>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <Paper className={classes.paper3}>
              <Typography variant='h6' />
              <List>
                <ListItem>
                  <ListItemText
                    primary={FakeDataHistory.length}
                    secondary={lang[specLang].loads}
                  />
                  <ListItemAvatar>
                    <Avatar>
                      <VerticalAlignBottomIcon />
                    </Avatar>
                  </ListItemAvatar>
                </ListItem>
              </List>
            </Paper>
          </Grid>

          <Grid item xs={6}>
            <Paper className={classes.paper3}>
              <Typography variant='h6' />
              <List>
                <ListItem>
                  <ListItemText
                    primary={(
                      FakeDataHistory.reduce(function (prev, cur) {
                        return prev + cur.peso_neto
                      }, 0) / 1000
                    ).toFixed(2)}
                    secondary={lang[specLang].tones}
                  />
                  <ListItemAvatar>
                    <Avatar>
                      <TitleIcon />
                    </Avatar>
                  </ListItemAvatar>
                </ListItem>
              </List>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper>
              <List>
                <ListItem>
                  <ListItemText
                    primary={lang[specLang].graph_title}
                    secondary={lang[specLang].graph_subtitle}
                  />
                  <ListItemAvatar>
                    <Avatar>
                      <ListNumberedIcon />
                    </Avatar>
                  </ListItemAvatar>
                </ListItem>
              </List>
              <div>
                <ResponsiveContainer
                  width='95%'
                  height={100 + FakeDataAnalytics.length * 60}
                >
                  <BarChart
                    layout='vertical'
                    data={FakeDataAnalytics}
                    margin={{
                      top: 20,
                      right: 20,
                      bottom: 20,
                      left: 130
                    }}
                  >
                    <CartesianGrid stroke='#f5f5f5' />
                    <XAxis type='number' unit='%' />
                    <YAxis dataKey='name' type='category' />
                    <Tooltip content={<CustomTooltip />} />
                    <Legend />
                    <Bar
                      dataKey='Operario'
                      barSize={15}
                      name={lang[specLang].legend_name_worker}
                      fill='#E64A19'
                    />
                    <Bar
                      dataKey='Modelo'
                      barSize={15}
                      name={lang[specLang].legend_name_system}
                      fill='#FBC02D'
                    />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </Paper>
          </Grid>
        </Grid>
        <Dialog
          open={this.state.openFromCalendar}
          keepMounted
          onClose={this.handleCloseCalendar.bind(this)}
          aria-labelledby='alert-dialog-slide-title'
          aria-describedby='alert-dialog-slide-description'
        >
          <ScrapDatePicker
            maxDate={this.state.toDate.dateSelected}
            type='from'
            name='DESDE'
            selectedDate={this.state.fromDate.dateSelected}
            handleDateChange={this.handleDateChange.bind(this)}
          />
          <DialogActions>
            <Button onClick={this.handleCloseCalendar.bind(this)}>
              Cancelar
            </Button>
            <Button onClick={this.handleCloseCalendar.bind(this, 'from')}>
              Aceptar
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.openToCalendar}
          keepMounted
          onClose={this.handleCloseCalendar.bind(this)}
          aria-labelledby='alert-dialog-slide-title'
          aria-describedby='alert-dialog-slide-description'
        >
          <ScrapDatePicker
            minDate={this.state.fromDate.dateSelected}
            type='to'
            name='HASTA'
            selectedDate={this.state.toDate.dateSelected}
            handleDateChange={this.handleDateChange.bind(this)}
          />
          <DialogActions>
            <Button onClick={this.handleCloseCalendar.bind(this)}>
              Cancelar
            </Button>
            <Button onClick={this.handleCloseCalendar.bind(this, 'to')}>
              Aceptar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

export default withStyles(styles)(Analytics)
