// External packages
import React from "react";
import { List, ListItem, ListItemText, Typography } from "@material-ui/core";
import Collapse from "@material-ui/core/Collapse";
import { makeStyles } from "@material-ui/core/styles";
import { Switch, Route } from "react-router-dom";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { 
  History, 
  Analytics as ScrapAnalytics 
} from "../Scrap";
import {
  Work,
  Analytics as ElectrodesAnalytics,
  Gallery,
} from "kalybs-electrodes";
// Internal packages
import {
  SCRAP_ANALYTICS_ROUTE,
  SCRAP_HISTORY_ROUTE,
  ELECTRODES_LAST_ROUTE,
  ELECTRODES_GALLERY_ROUTE,
  ELECTRODES_ANALYITICS_ROUTE,
} from "../../Routes";
import Divider from "@material-ui/core/Divider";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

import { version } from '../../../package.json';

import Main from "../Home/Main";
import PageNotFound from "../PageNotFound";
import ScrapMenuList from "./ScrapMenuList";
import ElectrodesMenuList from "./ElectrodesMenuList";

const useStyles = makeStyles((theme) => ({
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  list: {
    width: 250,
    zIndex: theme.zIndex.drawer-200,
  },
  root:{
    width: "100%",
    overflow: "unset",
    padding: "10px",
  },
  barTopLogout: {
    position: "fixed",
    left: 0,
    bottom: 72,
    width:250,
  },
  barBottomLogout: {
    position: "fixed",
    left: 0,
    bottom: 24,
    width:250,
  },
  version: {
    position: "fixed",
    textAlign: 'center',
    left: 0,
    bottom: 0,
    width:250,
    fontWeight:600,
  },
}));

//#region SwitchMenu
export function SwitchMenu(props) {
  const classes = useStyles();
  return (
    <div
      className={classes.root}
    >
      <Switch>
        <Route path="/" exact render={() => <History 
          auth={props.auth}
        />} />
        <Route
          path={SCRAP_ANALYTICS_ROUTE}
          exact
          render={() => <ScrapAnalytics 
            auth={props.auth}
          />}
        />
        <Route path={SCRAP_HISTORY_ROUTE} exact render={() => <History 
          auth={props.auth}
        />} />
        <Route
          path={ELECTRODES_ANALYITICS_ROUTE}
          exact
          render={() => <ElectrodesAnalytics />}
        />
        <Route path={ELECTRODES_LAST_ROUTE} exact render={() => <Work />} />
        <Route
          path={ELECTRODES_GALLERY_ROUTE}
          exact
          render={() => <Gallery />}
        />
        <Route render={() => {<PageNotFound/>}} />
      </Switch>
    </div>
  );
}
//#endregion

//#region MenuKalybs
export function MenuKalybs(props) {
  const classes = useStyles();

  /* STATE */
  const [openScrapView, setOpenScrap] = React.useState(false);
  const [openElectrodesView, setOpenElectrodes] = React.useState(false);

  /* HANDLE STATE PROPERTIES */
  const handleClickScrap = () => {
    setOpenScrap(!openScrapView);
  };

  const handleClickElectrodes = () => {
    setOpenElectrodes(!openElectrodesView);
  };

  /* RENDER */
  return (
    <div className={classes.list} role="presentation">
      <div className={classes.toolbarIcon} />
      <List component="nav">
        <ListItem button onClick={handleClickScrap}>
          <ListItemText primary="Scrap" />
          {openScrapView ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openScrapView} timeout="auto" unmountOnExit>
          <ScrapMenuList toggleDrawer={props.toggleDrawer}></ScrapMenuList>
        </Collapse>
        <Divider></Divider>
        <ListItem button onClick={handleClickElectrodes}>
          <ListItemText primary="Electrodes" />
          {openElectrodesView ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openElectrodesView} timeout="auto" unmountOnExit>
          <ElectrodesMenuList
            toggleDrawer={props.toggleDrawer}
          ></ElectrodesMenuList>
        </Collapse>
        <Divider></Divider>
        <ListItem button key={"Log out"} onClick={props.signOut}
          className={classes.barBottomLogout}
        >
          <ListItemIcon>{<ExitToAppIcon />}</ListItemIcon>
          <ListItemText primary={"Log out"} />
        </ListItem>
        <Divider
          className={classes.barTopLogout}
        ></Divider>
        <Divider
          className={classes.barBottomLogout}
        ></Divider>
        <Typography variant='caption' className={classes.version}>
          v{version}
        </Typography>
        {/* <Divider></Divider>
        <div style={{
          backgroundColor: "#F8F8F8",
          borderTop: "1px solid #E7E7E7",
          textAlign: "center",
          padding: "20px",
          position: "fixed",
          left: "0",
          bottom: "0",
          height: "60px",
          width:250,
        }}>
          Hello
        </div> */}
      </List>
    </div>
  );
}
//#endregion
